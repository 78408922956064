@import '../../styles/mixins/global.scss';

.modal-body {
  .container {
    .customer-grid {
      margin: 20px 0 0 0 !important;
      grid-template-columns: repeat(1, 1fr);

      .customer-grid-items:nth-child(1) {
        display: none;
      }
    }
  }
}

.modal-body {
  height: calc(100vh - 116px);
  overflow: auto;
  overflow-x: hidden;

  @include breakpoint("max-md") {
    height: calc(100vh - 100px) !important;
  }

  .modal-body-top-align {
    margin-top: 30px;

    @include breakpoint("max-md") {
      margin-top: 20px;
    }
  }


}

.generate-box-center {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 9;

  .right-space-remove {
    padding-right: 0 !important;
  }

  .right-space-align {
    // padding-right: var(normal-pixel) * 6 !important;
    position: sticky;
  }

  .generate-box {
    width: 440px;
    background-color: var(--white-color);
    box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
    border-radius: 10px;
    padding: calc(var(--normal-pixel) * 4 - -2px);
    min-height: calc(100vh - 161px);
    position: relative;

    @include breakpoint("max-md") {
      min-height: calc(100vh - 141px);
    }

    @include breakpoint("max-sm") {
      min-height: calc(100vh - 122px);
    }

    .title-text {
      h3 {
        @include text-md-bold;
        font-weight: 600;
        color: var(--blue-color);
        margin-bottom: 22px;
      }
    }

    .new-invoice-heading {
      height: calc(100vh - 300px);

      .service-alignment {
        margin-bottom: calc(var(--normal-pixel) * 6);
        padding: calc(var(--normal-pixel) * 3 - 0px);
        border: 1px solid var(--border-color);
        box-shadow: 0px 0px 10px rgba(209, 217, 230, 0.3);
        border-radius: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;

        .service-name {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }

          span {
            @include text-sm-bold;
            font-size: calc(var(--normal-pixel) * 3 - 0px);
            color: var(--blue-color);
            padding-left: calc(var(--normal-pixel) * 2);
          }
        }
      }
    }
  }
}

.setting-button-alignment {
  button {
    width: 100%;
    height: 47px;
    @include text-sm;
    font-size: 15px;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }

  button:last-child {
    color: #e66666;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid rgba(230, 102, 102, 0.25);
    background-color: var(--white-color);
  }
}