.whatsapp-payment-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(246, 251, 255, 0.568627451);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;

  .wp-payement-modal-md {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
    border-radius: 10px;
    width: 440px;

    .add-wp-payment-footer-alignment {
      padding: 22px;
      display: flex;
      align-items: center;
      border-top: 1px solid #d1d9e6;

      justify-content: center;

      button {
        border: none;
        background: #1479ff;
        border-radius: 5px;
        cursor: pointer;
        font-family: "Poppins", sans-serif;
        color: #fff;
        padding: 12px 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .add-wp-payment-body-contnet-alignment {
      padding: 30px 22px;

      .main-input {
        padding: 0 0 22px 0;
        label {
          display: block;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #4f7194;
          font-family: "Poppins", sans-serif;
          padding: 0 0 2px 0;
        }
        .input-relative {
          position: relative;

          font-family: "Poppins", sans-serif;
          input {
            width: 100%;
            background: #ffffff;
            border: 1px solid #d1d9e6;
            border-radius: 5px;
            padding: 0 15px;
            font-family: "Poppins", sans-serif;
            color: #193566;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            height: 51px;

            &:focus {
              outline: none;
            }
          }

          .text-alignment {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;

            span {
              display: block;
              font-weight: 500;
              font-size: 14px;
              color: #193566;
              line-height: 21px;
            }
          }

          ::placeholder {
            font-weight: 500;
            font-size: 14px;
            font-family: "Poppins", sans-serif;
            color: #97a7c3;
            line-height: 21px;
          }
        }
      }

      .validity-text {
        padding: 0 0 22px 0;
        p {
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #193566;
          margin: 0;
        }
      }
    }

    .add-wp-payment-header {
      background: #ffffff;
      border-bottom: 1px solid #d1d9e6;
      border-radius: 10px 10px 0px 0px;
      padding: 22px;

      .add-wp-payment-header-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #193566;
        }

        div {
          width: 27px;
          height: 27px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: #edf5ff;

          img {
            max-width: 11px;
          }
        }
      }
    }
  }
}
