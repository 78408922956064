@import "../../styles/mixins/global.scss";

.admin-dashboard-all-content-alignment {
  padding: 0 30px;
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;

  .admin-dashboard-first-header-alignment {
    @include flex-center;
    padding: 22px 0;
    justify-content: space-between;

    .user-button-design {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        padding: 15px;
        background: #ffffff;
        border: 1px solid #d1d9e6;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        @include flex-center;
        margin-right: 10px;
        &:first-child {
          span {
            padding-left: 10px;
          }
        }
        span {
          font-size: 15px;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          color: #193566;
          line-height: 22px;
        }
      }
      .filter-btn {
        padding: 0;
        height: 53px;

        background: #ffffff;
        border: 1px solid #d1d9e6;
        border-radius: 5px;

        .live-dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #e66666;
          animation: blink 2s linear infinite;
        }

        @keyframes blink {
          0% {
            opacity: 0.25;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0.25;
          }
        }
      }
      .active {
        border: 1.5px solid rgba(20, 121, 255, 0.5);
        box-shadow: 0 0 0 2px #e8f2ff !important;
      }
      .loading {
        border: 1px solid rgba(20, 121, 255, 0.5) !important;
      }
      .live-btn {
        width: 91px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
      .users-btn {
        padding: 0 15px;
      }
    }

    .react-date-picker-style {
      background: #ffffff;
      border: 1px solid #d1d9e6;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      width: fit-content;
      padding: 15px;

      img {
        margin-right: 10px;
      }
      span {
        .react-datepicker-wrapper {
          .react-datepicker__input-container {
            width: fit-content;
            input {
              cursor: pointer;
              width: fit-content;
              border: none;
              outline: none;
              background: transparent;
              color: var(--blue-color);
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
            }
          }
        }
        .react-datepicker__tab-loop {
          .react-datepicker-popper {
            z-index: 999;
            .react-datepicker__day--disabled,
            .react-datepicker__month-text--disabled,
            .react-datepicker__quarter-text--disabled,
            .react-datepicker__year-text--disabled {
              color: #ccc !important;
            }
          }
        }
      }
    }
  }
}
