@import '../../../styles/mixins/global.scss';

.user-information-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background: #F7FBFF;
    z-index: 9999;
    width: 100%;
    height: 100%;
    -webkit-animation-name: round;
    animation-name: round;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;

    .full-page-header-alignment {
        background-color: var(--white-color);
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;
        @include flex-center;
        justify-content: space-between;
        padding: 30px;

        .header-title {
            @include flex-center;


            div {
                width: 45px;
                cursor: pointer;
                height: 45px;
                background-color: #edf5ff;
                border-radius: 3px;
                display: flex;
                margin-right: 30px;
                align-items: center;
                justify-content: center;
            }

            h2 {
                @include heading-3;
                color: var(--blue-color);
                margin: 0;
            }
        }
    }

    .full-body-page-top-alignment {
        padding: 28px 0;
        height: calc(100vh - 105px);
        overflow: auto;
        overflow-x: hidden;

        .user-information-container {
            padding: 0 60px;

            @include breakpoint("max-sm") {
                padding: 0 15px;
            }

            .grid {
                display: grid;
                grid-template-columns: 338px 753px;
                gap: 60px;

                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 24px;
                }

                .grid-items {
                    .all-data-style-alignment {
                        background-color: var(--white-color);
                        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
                        border-radius: 10px;
                        padding: 22px;

                        .box-title {
                            padding: 0 0 15px 0;

                            h5 {
                                font-size: 15px;
                                line-height: 22px;
                                font-weight: 600;
                                color: var(--blue-color);
                            }
                        }

                        .sub-total-top-alignment {
                            padding-top: 22px;
                        }

                        .all-text-grid-alignment {
                            .text-grid:last-child {
                                padding: 0 0 0 0;
                            }

                            .text-grid {
                                display: grid;
                                grid-template-columns: 205px 1fr;
                                gap: 30px;
                                padding: 0 0 15px 0;

                                @include breakpoint("max-sm") {
                                    grid-template-columns: repeat(1, 1fr);
                                    gap: 5px;
                                }

                                .text-grid-items {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    span {
                                        @include text-sm-medium;
                                        color: var(--blue-color);
                                        opacity: 0.5;
                                        display: block;
                                        font-size: 14px;
                                        word-break: break-all;
                                    }

                                    p {
                                        
                                        @include text-sm-medium;
                                        font-size: 14px;
                                        color: var(--blue-color);
                                        margin: 0;

                                        a {

                                            color: var(--blue-1);

                                            @include breakpoint("max-sm") {
                                                display: block;
                                                word-break: break-all;
                                                white-space: break-spaces;
                                            }
                                        }
                                    }
                                    .paid-button-style {
                                        button {
                                            padding: 1px 21px;
                                            height: 25px;
                                            background: #E8F2FF;
                                            font-size: 10px;
                                            line-height: 12px;
                                            color: #1479FF;
                                            @include pointer;
                                            border: none;
                                            font-weight: 600;
                                            border-radius: 3px;
                                            font-family: 'Poppins', sans-serif;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .first-section-alignment {
                        background-color: var(--white-color);
                        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
                        border-radius: 10px;
                        margin: 0 0 30px 0;

                        .red-icon-align {
                            border: none !important;

                            div {
                                .managerasPaid {
                                    color: var(--blue-1);
                                }

                                span {
                                    color: #E66666 !important;
                                }
                            }
                        }

                        .icon-text-alignment {
                            padding: 21px 22px;
                            @include flex-center;
                            border-bottom: 1px solid #D1D9D6;
                            @include pointer;

                            div+div {
                                padding-left: 10px;
                            }

                            div {
                                @include flex-center;

                                span {
                                    @include text;
                                    font-weight: 500;
                                    display: block;
                                    color: var(--blue-1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}


