@import '../../../../styles/mixins/global.scss';

.add-wharsapp-validity-all-content-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(246, 251, 255, 0.568627451);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999;

    .add-whatsapp-box-md {
        background: #FFFFFF;
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;
        width: 440px;

        .add-whatsapp-footer {
            border-top: 1px solid #D1D9E6;
            padding: 22px;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                cursor: pointer;
                font-family: 'Poppins', sans-serif;
                padding: 12px 20px;
                border: none;
                color: #fff;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                background: #1479FF;
border-radius: 5px;
            }
        }

        .add-wharsapp-body {
            padding: 30px 22px;

            .add-validity {
                label {
                    display: block;
                    font-weight: 500;
                    font-size: 14px;
                    color: #4F7194;
                    line-height: 21px;
                    padding:  0 0 2px  0;
                }

                .relative-input {
                    position: relative;
                }

                .days-text-alignment {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 15px;

                    span {
                        display: block;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: #193566;
                    }
                }

                input {
                    width: 100%;
                    background: #FFFFFF;
                    border: 1px solid #D1D9E6;
                    border-radius: 5px;
                    font-family: 'Poppins', sans-serif;
                    height: 51px;
                    padding: 0 15px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #193566;

                    &:focus {
                        outline: none;
                    }
                }
                ::placeholder {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #97A7C3;
                    font-family: 'Poppins', sans-serif;
                }
            }

            .current-validity {
                padding: 0 0 22px 0;
                p {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                    color: #193566;
                    margin: 0;
                }
            }
        }

        .add-whatsapp-header {
            background: #FFFFFF;
            border-bottom: 1px solid #D1D9E6;
            border-radius: 10px 10px 0px 0px;
            padding: 22px;

            .add-whatsapp-header-content-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    color: #193566;
                }

                div {
                    width: 27px;
                    height: 27px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    background-color: #edf5ff;
                    
                    img {
                        max-width: 11px;
                    }
                }
            }
        }
    }
}