// @use './mixins/breakpoint.scss';
// @use './mixins/typography.scss';
// @use './mixins/columns.scss';
// @use './function/columns.scss';
// @use './layout/container.scss';
// @use './mixins/index.scss';

@import "../function/columns.scss";
@import "../layout/container.scss";
@import "../mixins/columns.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/index.scss";
@import "../mixins/typography.scss";
@import "../theme/theme.scss";
@import "../flexbox/flexbox.scss";

.blue-button {
  button {
    padding: 12px 40px;
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    border-radius: 5px;
    @include text-sm-bold;
    @include pointer;

    @include breakpoint("max-sm") {
      padding: 10px 22px;
    }
  }
}

.blue-outline-button {
  display: flex;
  gap: 30px;
  button {
    width: 150px;
    height: 47px;
    border: 1px solid #c4deff;

    // padding: 12px 40px;
    background-color: var(--white-color);
    color: #1479ff;
    border-radius: 5px;
    @include text-sm-bold;
    @include pointer;

    @include breakpoint("max-sm") {
      padding: 10px 22px;
    }
  }
}

.input {
  label {
    @include text-sm;
    font-weight: 500;
    color: var(--label-color);
    padding: 0 0 2px 0;
    font-size: 14px;
    display: block;
  }

  input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #d1d9e6;
    border-radius: 3px;
    @include text-sm-medium;
    font-size: 14px;
    color: var(--blue-color);
    height: 51px;
    padding: 0 15px;

    &:focus {
      outline: none;
    }
  }

  textarea {
    width: 100%;
    background: #ffffff;
    border: 1px solid #d1d9e6;
    border-radius: 3px;
    @include text-sm-medium;
    color: var(--blue-color);
    height: 72px;
    font-size: 14px;
    padding: 15px;

    &:focus {
      outline: none;
    }
  }

  ::placeholder {
    @include text-sm-medium;
    color: #97a7c3;
    font-size: 14px;
  }
}
