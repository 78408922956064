@import "../../../styles/mixins/global.scss";

.account-modal-manager-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background: #f7fbff;
  z-index: 9999;
  width: 100%;
  height: 100%;
  -webkit-animation-name: round;
  animation-name: round;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;

  .full-page-body-top-alignment {
    padding: 28px 0;
    height: calc(100vh - 105px);
    overflow: auto;
    overflow-x: hidden;

    .account-manager-table-design {
      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          border-bottom: 1px solid #d1d9e6;

          th {
            padding: 30px 10px;
            @include text;
            font-weight: 500;
            color: var(--gray-9);
          }

          td {
            padding: 30px 10px;

            .amount-text-style {
              span {
                @include text;
                font-weight: 500;
                color: var(--blue-color);
                display: block;

                a {
                  font-family: "Roboto", sans-serif;
                }
              }
            }

            .normal-text-style {
              span {
                @include text;
                font-weight: 500;
                color: var(--blue-color);
              }
            }
          }
        }
      }
    }

    .all-content-alignment {
      display: flex;
      justify-content: center;

      .account-modal-width {
        width: 1198px;

        @include breakpoint("max-sm") {
          width: 100%;
          padding: 0 0 0 20px;
        }

        .select-relative-div {
          position: relative;
        }

        .relative-custom-select {
          position: relative;
          margin: 0;
          cursor: pointer;

          @include breakpoint("max-sm") {
            margin: 0 0 16px 0;
          }

          .custom-select-react-dropdown-open {
            overflow: auto;
            max-height: 260px;
            border-top: none;
            transition: 0.3s ease-in-out;
          }

          .custom-select-react-dropdown-hidden {
            max-height: 0px;
            overflow: hidden;
            transition: 0.3s ease-in-out;
          }

          .custom-select-react-dropdown {
            position: absolute;
            z-index: 99999;
            box-shadow: 0 0 30px #d1d9e680;
            border-radius: 4px;
            background-color: var(--white-color);
            width: 183px;

            .all-menu-alignment {
              .menu-list {
                padding: 10px;
                border-bottom: 1px solid #d1d9e6;
                cursor: pointer;

                span {
                  @include text-small;
                  color: var(--blue-1);
                  font-size: 14px;
                  display: block;
                }
              }
            }
          }
        }

        .sub-header-modal-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          @include breakpoint("max-sm") {
            display: block;
            margin-right: 15px;
          }

          h5 {
            @include heading-8;
            color: var(--blue-color);
            margin: 0;

            @include breakpoint("max-sm") {
              @include text-md;
              font-weight: 600;
            }

            span {
              font-weight: 500;
              font-family: "Roboto", sans-serif;
            }
          }

          .search-relative {
            position: relative;
            background-color: var(--white-color);

            @include breakpoint("max-sm") {
              margin: 16px 0 0 0;
            }

            .search-icon-alignment {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              @include flex-center;
              right: 11px;
              @include pointer;
            }

            input {
              background-color: var(--white-color);
              padding: 0 15px;
              border: 1px solid #c4deff;
              border-radius: 5px;
              height: 38px;
              @include text-small-bold;
              color: var(--blue-1);

              @include breakpoint("max-sm") {
                width: 100%;
              }

              &:focus {
                outline: none;
              }
            }

            ::placeholder {
              @include text-small-bold;
              color: var(--gray-9);
            }
          }
        }
      }
    }
  }

  .full-page-header-alignment {
    background-color: var(--white-color);
    box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
    border-radius: 10px;
    @include flex-center;
    justify-content: space-between;
    padding: 30px;

    .new-statement-modal-desgin-alignment {
      background: #ffffff;
      border: 1px solid #d1d9e6;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      padding: 13px 15px 13px 20px;
      width: 140px;

      @include breakpoint("max-sm") {
        width: 180px;
        padding: 8px;
      }

      input {
        @include breakpoint("max-sm") {
          font-size: 11px;
          width: 135px;
        }
      }

      img {
        margin-right: 10px;
      }

      span {
        input {
          border: none;
          font-size: 15px;
          font-weight: 500;
          font-family: "Poppins", sans-serif !important;
          line-height: 22px;
          max-width: 90px;
          text-align: start;
          cursor: pointer;
          color: #193566 !important;
          outline: none;
          caret-color: transparent !important;
        }
      }
      .date-picker-style {
        .react-datepicker__tab-loop {
          .react-datepicker-popper {
            // transform: translate(0px, 32px) !important;
            transform: translate(-20%, 81px) !important;
            .react-datepicker {
              border: 1px solid #c4ddff;
              .react-datepicker__month-container {
                .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
                  background-color: transparent !important;
                  border-bottom-color: transparent;
                  padding-top: 14px;
                }
                .react-datepicker__month {
                  .react-datepicker__month-wrapper {
                    .react-datepicker__month-text {
                      padding: 10px;
                    }
                    .react-datepicker__month-text--keyboard-selected {
                      background-color: #1479ff;
                    }
                  }
                }
              }
              .react-datepicker__navigation {
                top: 8px;
                border: none !important;
              }
            }
          }
        }
      }
    }

    .header-title {
      @include flex-center;

      div {
        width: 45px;
        cursor: pointer;
        height: 45px;
        background-color: #edf5ff;
        border-radius: 3px;
        display: flex;
        margin-right: 30px;
        align-items: center;
        justify-content: center;
      }

      h2 {
        @include heading-3;
        color: var(--blue-color);
        margin: 0;
      }
    }
  }
}
