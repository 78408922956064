@import '../../styles/mixins/global.scss';

.login-box-center-alignment {
    width: 100%;
    height: 100vh;

    .box-center-alignment {
        @include flex-center;
        justify-content: center;
        padding: 134px 0 0 0;
       

        .login-box {
            width: 400px;
            font-family: 'Lato', sans-serif;

            .logo-center-alignment {
                @include flex-center;
                justify-content: center;
                padding: 0 0 60px 0;
                @include pointer;
                

                img {
                    max-width: 250px;
                    font-family: 'Lato', sans-serif;

                }
                @include breakpoint("max-sm") {
                    padding: 0 0 20px 0;
                    img {
                        max-width: 200px;
    
                    }
                }
            }

            .login-button {
                padding: 50px 0 0 0;
                @include breakpoint("max-sm") {
                    padding: 30px 0 0 0;
                 
                }

                button {
                    border: none;
                    width: 100%;
                    font-family: 'Lato', sans-serif;
                    color: var(--white-color);
                    background-color: var(--primary-color);
                    height: 48px;
                    font-size: 15px;
                    line-height: 17px;
                    border-radius: 3px;
                    font-weight: 700;
                    @include pointer;
                }
            }

            .input {
                margin: 0 0 30px 0;

                label {
                    font-size: 15px;
                    font-family: 'Lato', sans-serif;
                    font-weight: 500;
                    line-height: 18px;
                    font-weight: 500;
                    padding: 0 0 5px 0;
                    color: var(--gray-4);
                    display: block;
                }

                .relative-div {
                    position: relative;

                    .icon-alignment {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        @include flex-center;
                        justify-content: center;
                        @include pointer;
                        right: 15px;
                    }
                }

                input {
                    font-family: 'Lato', sans-serif;
                    font-size: 15px;
                    line-height: 19px;
                    width: 100%;
                    height: 48px;
                    font-weight: 500;
                    color: var(--blue-color);
                    background: #FFFFFF;
                    border: 1px solid #D1D9E6;
                    border-radius: 3px;
                    padding: 0 15px;

                    &:focus {
                        outline: none;
                    }
                }

                ::placeholder {
                    font-family: 'Lato', sans-serif;
                    color: var(--gray-9);
                    font-size: 15px;
                    line-height: 19px;
                    font-weight: 500;
                }
            }

            .login-text-style {
                padding: 0 0 50px 0;
                @include breakpoint("max-sm") {
                    padding: 0 0 30px 0;
                 
                }

                p {
                    font-size: 22px;
                    line-height: 26px;
                    color: var(--blue-color);
                    margin: 0 0 15px 0;
                    font-weight: 700;
                    @include text-center;
                    font-family: 'Lato', sans-serif;
                }

                span {
                    font-size: 14px;
                    line-height: 16px;
                    font-family: 'Lato', sans-serif;
                    font-weight: 500;
                    color: var(--gray-9);
                    display: block;
                    @include text-center;
                }
            }
        }
    }
}