@import "../../../styles/mixins/global.scss";

.generate-coupon-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(246, 251, 255, 0.5686274509803921);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999;

    .generate-coupon-modal {
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;
        width: 460px;

        background-color: var(--white-color);

        .generate-coupon-footer {
            padding: 30px;
            border-top: 1px solid #d1d9e6;
            .disable-blue-button {
                @include flex-center;
                justify-content: center;
                button {
                    padding: 12px 40px;
                    color: var(--white-color);
                    border: none;
                    border-radius: 5px;
                    @include text-sm-bold;
                    @include pointer;
                    background: #1479ff33;
                }
            }
            .blue-button {
                @include flex-center;
                justify-content: center;

                button {
                    padding: 12px 39px;
                }
            }
        }

        .generate-coupon-modal-body {
            padding: 30px 22px 0 22px;
            .disuount-input-grid-items {
                .form-group {
                    position: relative;

                  label {
                    line-height: 21px !important;
                  }

                }
              }
              .disuount-input-grid {
                display: grid;
                grid-template-columns: minmax(0, 1fr) 73px;
                justify-content: space-between;
                gap: 0 15px;
                margin-bottom: 15px;
            
                .disuount-input-grid-items:nth-child(2) {
                  margin-top: 24px;
                }
            
                .disuount-input-grid-items {
                  .icon-input-align {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 15px;
            
                    img {
                      width: 12px;
                      height: 6px;
                    }
                  }
                }
              }

            .input {
                margin: 0 0 30px 0;

                .validation-error-message{
                  color: red;
                  font-size: smaller;
                  opacity: 0.7;
                  font-weight: 700;
                }
            }

            .input-relative-div {
                position: relative;
                

                .text-right-alignment {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 13px;
                    @include flex-center;

                    a {
                        @include text-sm-medium;
                        font-size: 14px;
                        color: #1479ff;
                        font-weight: 500;
                    }

                    span {
                        @include text-sm-medium;
                        font-size: 14px;
                        font-weight: 500;
                        color: var(--blue-color);
                    }
                }
            }
        }

        .generate-coupon-modal-header {
            padding: 25px;
            @include flex-center;
            box-shadow: 0px 4px 10px rgba(209, 217, 230, 0.5);

            div {
                width: 33px;
                cursor: pointer;
                height: 33px;
                background-color: #edf5ff;
                border-radius: 3px;
                display: flex;
                margin-right: 25px;
                align-items: center;
                justify-content: center;
            }

            h2 {
                @include heading-8;
                color: var(--blue-color);
            }
        }
    }
}
.sub-menu-open {
    width: 100%;
    position: absolute;
  }

.menu-design-box {
    box-shadow: 0px 0px 10px rgba(209, 217, 230, 0.75);
    background-color: var(--white-color);
    border-radius: 3px;
    padding: 6px 7px;
    width: 50px;
    
  
  
    .list-style-design:last-child {
      border-bottom: none;
      padding-bottom: 0px !important;
      cursor: pointer;
    }
  
    .list-style-design {
      padding: 5px 0;
      border-bottom: 1px solid #d1d9e6;
      cursor: pointer;
      color: var(--blue-color);
  
      &:hover {
        color: var(--blue-color);
      }
  
      span {      
         @include text-sm;
        display: flex;
        font-family: 'Roboto', sans-serif;
        text-align: center;
        justify-content: center;
      }
    }
  }

  .sub-menu-hidden {
    max-height: 0px;
    overflow: hidden;
    transition: .3s ease-in-out;
  }

  .sub-menu-show {
    max-height: calc(100% - 100px);
    transition: .3s ease-in-out;
  
  }
 
  
.form-group {
    label {
        @include text-sm;
        font-weight: 500;
        color: var(--label-color);
        padding: 0 0 2px 0;
        font-size: 14px;
        display: block;
        &:focus {
            outline: none;
          }

          a {
            font-family: 'Roboto', sans-serif;
            font-weight: 500 !important;
          }
     
    }
  
    textarea {
      padding: 15px;
      width: 100%;
      border: 1px solid #d1d9e6;
      @include medium-border-radius;
      @include text-sm-bold;
      color: var(--blue-color);
      font-weight: 500;
  
      &:focus {
        outline: none;
      }
    }
  
    input,
    select {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #D1D9E6;
        border-radius: 3px;
        @include text-sm-medium;
        font-size: 14px;
        color: var(--blue-color);
        height: 51px;
        padding: 0 15px;

        &:focus {
            outline: none;
        }
    }
  
    ::placeholder {
        @include text-sm-medium;
        color: #97A7C3;
        font-size: 14px;
    }
  
    select::-ms-expand {
      display: none;
    }
  
    .dropdown-relative {
      position: relative;
  
      option {
        @include text-md-bold;
        color: var(--blue-color);
  
      }
    }
  
  
    select {
      -webkit-appearance: none;
      appearance: none;
    }
  
    ::placeholder {
        @include text-sm-medium;
        color: #97A7C3;
        font-size: 14px;
    }
  
    .down-arrow-align {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
  
      img {
        width: 12px;
        height: 6px;
      }
  
      span {
        //font-family: 'Roboto', sans-serif;
        font-weight: 500;
        @include text-md-bold;
        color: var(--blue-color);
  
  
      }
    }
  }

  .rs-roboto-change {
    span {
      font-family: 'Roboto', sans-serif;
      font-weight: 500 !important;
    }
  }
 