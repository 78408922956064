@import "../../../styles/mixins/global.scss";

.invoice-generated-chart-box-content-box {
  background: #ffffff;
  border: 1px solid #d1d9e6;
  border-radius: 10px;
  margin: 0 0 30px 0;

  .box-body-content-alignment {
    .empty-sapce {
      padding: 50px 0 0 0;
    }

    .grid {
      display: grid;
      grid-template-columns: 50px 1fr;
      gap: 0;
      border-bottom: 1px solid #d1d9e6;
      height: 300px;

      .grid-items:nth-child(1) {
        border-right: 1px solid #d1d9e6;
      }

      .grid-items:nth-child(2) {
        padding-left: 50px;

        .chart-items {
          // padding: 50px 0 50px 0;
          width: 100%;
          ::-webkit-scrollbar {
            display: none !important;
          }
          .invoice-chart-container {
            height: 100%;
            width: 100%;
            position: relative;
            overflow-y: hidden;

            div {
              height: 100%;

              .apexcharts-canvas {
                position: absolute;

                bottom: -24px;
                z-index: 99;
                .apexcharts-svg {
                  foreignObject {
                    .apexcharts-legend {
                      height: fit-content;
                      width: fit-content;
                      display: block;
                      background: #ffffff;
                      border: 1px solid #c4deff;
                      border-radius: 6px;
                      transform: translate(calc(100vw - 280px), -20px);
                      padding: 15px;
                      .apexcharts-legend-series {
                        height: unset;
                      }
                    }
                  }
                  .apexcharts-bar-area {
                    width: 30px !important;
                    max-width: 30px !important;
                  }
                  .apexcharts-graphical {
                    transform: translate(50px, 30px);
                  }
                }
                .apexcharts-tooltip {
                  width: max-content;
                  height: max-content;
                  transform: translate(-90px, 0);
                  .apexcharts-tooltip-title {
                    background: var(--blue-color);
                    color: #ffffff;
                  }
                  .apexcharts-tooltip-series-group {
                    display: flex;
                    align-items: center;
                  }
                }
              }
            }
          }

          .adjust-legend-customer {
            div {
              .apexcharts-canvas {
                .apexcharts-svg {
                  .apexcharts-legend {
                    transform: translate(calc(100vw - 330px), -20px) !important;
                  }
                }
              }
            }
          }

          .adjust-legend-sale-item {
            div {
              .apexcharts-canvas {
                .apexcharts-svg {
                  .apexcharts-legend {
                    transform: translate(calc(100vw - 260px), -20px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .box-header {
    padding: 22px;
    @include flex-center;
    justify-content: space-between;
    border-bottom: 1px solid #d1d9e6;

    div {
      &:first-child {
        flex: 1;
      }

      span {
        @include heading-8;
        display: block;
        color: var(--blue-color);
      }

      button {
        margin-left: 20px;
        background: #ffffff;
        border: 1px solid #c4deff;
        border-radius: 6px;
        color: #1479ff;
        @include text-small-bold;
        cursor: pointer;
        padding: 10px 20px;
      }
    }
  }
}
