@import "../../../styles/mixins/global.scss";

.differences-chart-all-box-content-alignment {
  background: #ffffff;
  border: 1px solid #d1d9e6;
  border-radius: 10px;
  margin: 0 0 30px 0;

  .box-body-content-alignment {
    width: 100%;
    .empty-sapce {
      padding: 50px 0 0 0;
    }

    .grid {
      display: grid;
      grid-template-columns: 50px 1fr;
      gap: 0;
      height: 300px;
      border-bottom: 1px solid #d1d9e6;

      .grid-items:nth-child(1) {
        border-right: 1px solid #d1d9e6;
      }

      .grid-items:nth-child(2) {
        padding-left: 0px;

        .chart-items {
          height: 100%;
          width: 100%;
          ::-webkit-scrollbar {
            display: none !important;
          }

          .difference-chart-container {
            height: 100%;
            width: 100%;
            position: relative;
            overflow-x: scroll;
            overflow-y: hidden;

            div {
              height: 100%;

              .apexcharts-canvas {
                position: absolute;

                bottom: -24px;
                z-index: 99;
                .apexcharts-svg {
                  foreignObject {
                    .apexcharts-legend {
                      height: fit-content;
                      width: fit-content;
                      display: block;
                      background: #ffffff;
                      border: 1px solid #c4deff;
                      border-radius: 6px;
                      transform: translate(calc(100vw - 280px), -20px);
                      padding: 15px;
                      .apexcharts-legend-series {
                        height: unset;
                      }
                    }
                  }
                  .apexcharts-bar-area {
                    width: 30px !important;
                    max-width: 30px !important;
                  }
                  .apexcharts-graphical {
                    transform: translate(50px, 30px);
                  }
                }
                .apexcharts-tooltip {
                  width: max-content;
                  height: max-content;
                  transform: translate(-90px, 0);
                  .apexcharts-tooltip-title {
                    background: var(--blue-color);
                    color: #ffffff;
                  }
                  .apexcharts-tooltip-series-group {
                    display: flex;
                    align-items: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .box-header {
    padding: 22px;
    @include flex-center;
    justify-content: space-between;
    border-bottom: 1px solid #d1d9e6;

    div {
      h2 {
        @include heading-8;
        display: block;
        color: var(--blue-color);
      }
    }

    .service-relative {
      position: relative;

      .service-dropdown-hidden {
        max-height: 0px;
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }

      .service-dropdown-show {
        max-height: calc(100vh - 0px);
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }

      .service-dropdown {
        width: 100%;
        position: absolute;
        right: 0;
        background: #ffffff;
        border-radius: 6px;
        z-index: 999;

        div {
          border: 1px solid #c4deff;
          border-radius: 6px;

          span {
            display: block;
            padding: 10px 15px;
            border-bottom: 1px solid #d1d9e6;
            @include text-sm-bold;
            color: #1479ff;
            @include pointer;
          }
        }
      }

      button {
        background: #ffffff;
        border: 1px solid #c4deff;
        border-radius: 6px;
        width: 123px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include pointer;

        span {
          display: block;
          @include text-small-bold;
          color: #1479ff;
        }
      }
    }

    .flex-items-sec {
      @include flex-center;
    }

    .sales-relative {
      position: relative;
      margin-left: 15px;

      .service-dropdown-hidden {
        max-height: 0px;
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }

      .service-dropdown-show {
        max-height: calc(100vh - 0px);
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }

      .service-dropdown {
        width: 100%;
        position: absolute;
        right: 0;
        background: #ffffff;
        border-radius: 6px;
        z-index: 9999;

        div {
          border: 1px solid #c4deff;
          border-radius: 6px;

          span {
            display: block;
            padding: 10px 15px;
            border-bottom: 1px solid #d1d9e6;
            @include text-sm-bold;
            color: #1479ff;
            @include pointer;
          }
        }
      }

      button {
        background: #ffffff;
        border: 1px solid #c4deff;
        border-radius: 6px;
        width: 210px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include pointer;

        span {
          display: block;
          @include text-small-bold;
          color: #1479ff;
        }
      }
    }
  }
}
