@import '../../../styles/mixins/global.scss';


.coupon-code-details-modal-body {
  display: flex;
  flex-direction: column;
  height: 459px;
  width: 100%;
  // justify-content: space-between;
  padding: 22px !important;

  .chat-view-design {
    position: relative;
    background: #e9e9eb;
    border-radius: 13px;
    padding: 10px;
    margin: 0 15px;

    &::before {
      background-image: url("../../../assets/icons/chat.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 15px;
      height: 12px;
      position: absolute;
      bottom: -2px;
      left: -7px;
      content: "";
    }

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #193566;
      margin: 0 0 10px 0;
    }

    span {
      font-size: 12px;
      line-height: 15px;
      color: #000;
      font-weight: 500;
      display: block;

      a {
        color: #1479ff;
      }
    }
  }

  .message {
    margin-bottom: 22px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #193566;

    p {
      margin: 0;
    }
  }

  .image {
    width: 100%;
    border: 1px solid #D1D9E6;
    padding: 26px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #193566;

    span {
      color: #193566 !important;
      font-weight: 500;
      cursor: pointer;
    }


  }
}