@import '../../../styles/mixins/global.scss';

.account-modal-manager-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background: #F7FBFF;
    z-index: 9999;
    width: 100%;
    height: 100%;
    -webkit-animation-name: round;
    animation-name: round;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;


    .full-page-body-top-alignment {
        padding: 28px 0;
        height: calc(100vh - 105px);
        overflow: auto;
        overflow-x: hidden;

        @include breakpoint("max-sm") {
            height: calc(100vh - 72px);
            padding: 28px 0  10px 0;

        }

        .account-manager-table-design {
            @include breakpoint("max-sm") {
                width: 100%;
                overflow: auto;
                height: calc(100vh - 144px);
            }

            table {
                width: 100%;
                border-collapse: collapse;

                .blue-text-style {
                    text-decoration-line: underline;
                    color: #1479FF !important;
                    cursor: pointer;
                }

                .active-button-style {
                    button {
                        border: none;
                        color: #1479FF;
                        font-size: 12px;
                        padding: 5px 23px;
                        line-height: 18px;
                        font-weight: 600;
                        background: #E8F2FF;
                        border-radius: 3px;
                        @include pointer;
                    }
                    .expired {
                        color: #6F737D;
                        background: #DBE0E5;
                    }
                }

                tr {
                    border-bottom: 1px solid #D1D9E6;

                    th {
                        padding: 30px 10px;
                        @include text;
                        font-weight: 500;
                        color: var(--gray-9);

                        @include breakpoint("max-sm") {
                            padding: 20px 10px;
                            white-space: nowrap;
                        }
                    }

                    td {
                        padding: 30px 10px;

                        @include breakpoint("max-sm") {
                            padding: 10px 10px;
                            white-space: nowrap;
                        }

                        .amount-text-style {
                            span {
                                @include text;
                                font-weight: 500;
                                color: var(--blue-color);
                                display: block;

                                a {
                                    font-family: 'Roboto', sans-serif;
                                }
                            }
                        }

                        .normal-text-style {
                            span {
                                @include text;
                                font-weight: 500;
                                color: var(--blue-color);
                            }
                        }

                        .inactive-text-style{
                            span{
                                @include text;
                                font-weight: 500;
                                color: var(--gray-9);
                            }
                        }

                    }
                }
            }
        }

        .all-content-alignment {
            display: flex;
            justify-content: center;
          
            .coupon-modal-width {
                width: 987px;
                @include breakpoint("max-sm") {
                    width: 100%;
                    padding: 0 0 0 20px ;
                }
    

                .sub-header-modal-title {
                    h5 {
                        @include heading-8;
                        color: var(--blue-color);
                        margin: 0;
                    }
                }
            }
        }

    }

    .full-page-header-alignment {
        background-color: var(--white-color);
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;
        @include flex-center;
        justify-content: space-between;
        padding: 30px;

        @include breakpoint("max-sm") {
            padding: 15px;
        }

        .header-title {
            @include flex-center;


            div {
                width: 45px;
                cursor: pointer;
                height: 45px;
                background-color: #edf5ff;
                border-radius: 3px;
                display: flex;
                margin-right: 30px;
                align-items: center;
                justify-content: center;

                @include breakpoint("max-sm") {
                    width: 35px;
                    height: 35px;
                    margin-right: 15px;

                    img {
                        max-width: 14px;
                    }
                }
            }

            h2 {
                @include heading-3;
                color: var(--blue-color);
                margin: 0;

                @include breakpoint("max-sm") {
                    @include heading-6-bold;
                    font-size: 17px;
                }
            }
        }
    }
}