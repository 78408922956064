@import '../../styles/mixins/global.scss';

header {
    width: 100%;
    padding: 30px;
    background-color: var(--white-color);
    box-shadow: 0px 0px 30px rgba(25, 53, 102, 0.05);
    border-radius: 0px 0px 10px 10px;

    @include breakpoint("max-sm") {
        padding: 15px;
    }

    .header-alignment {
        @include flex-center;
        justify-content: space-between;

        .logo {
            @include breakpoint("max-sm") {
                @include flex-center;
            }

            img {
                max-width: 180px;
                height: 45px;
                @include pointer;

                @include breakpoint("max-sm") {
                    max-width: 120px;
                    height: 30px;
                }
            }
        }

        .menu {
            @include flex-center;
        }

        .menu-bar {
            @include breakpoint("max-sm") {
                padding-left: 15px;
            }

            img {
                @include pointer;
            }
        }

        .add-new-user {
            margin-right: 26px;

            @include breakpoint("max-sm") {
                margin: 0;
            }

            button {
                background-color: var(--primary-color);
                color: var(--white-color);
                padding: 11px 22px;
                border: none;
                @include pointer;
                @include text-sm-bold;
                border-radius: 5px;
            }

        }
    }
}