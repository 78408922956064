:root {
    --blue-color: #193566;
    --gray-9: #97A7C3;
    --gray-4: #475D85;
    --primary-color: #1479FF;
    --white-color: #fff;
    --light-color: #edf5ff;
    --blue-1: #1479FF;
    --border-color: #D1D9D6;
    --border-color-1: #D1D9E6;;
    --normal-pixel: 5px;
    --label-color: #4F7194
}