@import '../../../../styles/mixins/global.scss';
.input-relative-icon {
    position: relative;

    .icon-input-align {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 35px;

        img {
            cursor: pointer;
            width: 15px;
            height: 7px;
        }
    }
  .category-sub-menu-design {
      position: absolute;
      background-color: #fff;
      z-index: 99999;
      box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
      background-color:#fff;
      width: 100%;
  }

}
.form-top-align-space {
    margin: 10px 0;
}
.category-sub-menu-design-hidden {
    max-height: 0px;
    overflow: hidden;
    transition: 0.1s ease-in-out;
}

.category-sub-menu-design-show {
    overflow: auto;
    max-height: 260px;
    border-top: none !important;
    border: 1px solid rgba(209, 217, 230, 0.5)rgba(209, 217, 230, 0.5);
    transition: 0.1s ease-in-out;
}
.category-sub-menu-option {
    padding: 12px;
    border-bottom: 1px solid rgba(209, 217, 230, 0.5);
    min-height: 45px;
    cursor: pointer;
    span {
        font-size: 14px;
        word-break: break-all;
        color: var(--blue-color);
        font-weight: 500 !important;
    }
}