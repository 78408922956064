@import "../../../styles/mixins/global.scss";

.account-upgrade-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(246, 251, 255, 0.5686274509803921);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;

  .account-upgrade-small-modal {
    width: 460px;
    background-color: var(--white-color);
    box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
    border-radius: 10px;

    @include breakpoint("max-sm") {
      width: calc(100% - 40px);
    }

    .account-upgrade-small-modal-footer {
      padding: 30px;

      @include breakpoint("max-sm") {
        padding: 20px;
      }

      border-top: 1px solid #d1d9e6;

      .blue-button {
        @include flex-center;
        justify-content: center;

        button {
          padding: 12px 21px;
        }
      }
    }

    .account-upgrade-small-modal-body {
      padding: 30px 22px 0 22px;
      height: 622px;
      overflow-y: scroll;

      @include breakpoint("max-sm") {
        height: calc(100vh - 210px);
        overflow: auto;
      }

      .box-title-alignment {
        padding: 0 0 30px 0;

        p {
          @include text;
          color: var(--blue-color);
          font-weight: 500;
          margin: 0;
        }
      }

      .input {
        margin: 0 0 30px 0;
      }

      .account-relative-div {
        position: relative;

        .days-text-alignment {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          @include flex-center;
          @include pointer;
          right: 13px;

          span {
            @include text-sm-medium;
            font-size: 14px;
            color: var(--blue-color);
            @include pointer;

            a {
              font-family: "Roboto", sans-serif;
            }
          }
        }
      }
      .feature-list-container {
        .title {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #193566;
        }
        .feature-list {
          display: flex;
          flex-direction: column;
          gap: 22px;
          padding: 22px 0;
          .feature-list-item {
            width: 400px;
            height: 51px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            border: 1px solid rgba(20, 121, 255, 0.5);
            cursor: pointer;

            p {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #193566;
            }

            .plan-type {
              height: 21px;
              padding: 0 7px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #f3f8ff;
              border: 1px solid rgba(20, 121, 255, 0.5);
              border-radius: 4px;

              font-weight: 600;
              font-size: 10px;
              line-height: 15px;
              color: #1479ff;
            }
          }
          .selected {
            background: linear-gradient(0deg, #f3f8ff, #f3f8ff), #ffffff;
          }
        }
      }
    }

    .account-upgrade-small-modal-header {
      @include flex-center;
      box-shadow: 0px 4px 10px rgba(209, 217, 230, 0.5);
      background-color: var(--white-color);
      padding: 25px;

      .close-button {
        width: 33px;
        cursor: pointer;
        height: 33px;
        background-color: #edf5ff;
        border-radius: 3px;
        display: flex;
        margin-right: 25px;
        align-items: center;
        justify-content: center;
      }

      h2 {
        @include heading-8;
        color: var(--blue-color);
        margin: 0;
      }

      .save-button {
        flex: 1;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: end;
        background-color: unset;

        button {
          width: 91px;
          height: 38px;
          padding: 0;
          margin: 0;
          background: #1479ff;
          font-family: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          border-radius: 5px;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #ffffff;
          cursor: pointer;
        }

        .disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }
  }
}
