@import '../../../styles/mixins/global.scss';

.coupon-code-details-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(246, 251, 255, .5686274509803921);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999;

    .coupon-code-details-modal {
        width: 504px;
        height: 545px;
        background-color: var(--white-color);
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;

        .coupon-code-details-modal-body {
            padding: 0 25px;    
            height: calc(100% - 123px);
            overflow-y: scroll;

            table {
                width: 100%;
                border-collapse: collapse;

                tr {
                    th {
                        padding: 15px 10px;
                        @include text;
                        font-weight: 500;
                        color: var(--gray-9);
                    }

                    td {
                        padding: 15px 10px;

                        @include text;
                        font-weight: 500;
                        color: var(--blue-color);
                    }
                }
            }
        }

        .coupon-code-details-modal-header {
            @include flex-center;
            padding: 25px;
            box-shadow: 0px 4px 10px rgba(209, 217, 230, 0.5);

            div {
                width: 33px;
                cursor: pointer;
                height: 33px;
                background-color: #edf5ff;
                border-radius: 3px;
                display: flex;
                margin-right: 25px;
                align-items: center;
                justify-content: center;
            }

            h2 {
                @include heading-8;
                color: var(--blue-color);
                margin: 0;
            }
        }
    }
}