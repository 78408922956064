.spinner {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #7daff2 94%, #0000) top/11.5px 11.5px no-repeat,
    conic-gradient(#0000 30%, #7daff2);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 11.5px), #000 0);
  animation: spinner-c7wet2 0.8s infinite linear;
}
@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}
.loader-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 50%;
  height: 100%;
}

.cus-modal-loaders {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f7fbff;
  z-index: 999999999999;
  opacity: 0.5;
  animation-name: round;
  animation-duration: 0.3s;
  animation-timing-function: linear;
}
