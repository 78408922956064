@import "../../../styles/mixins/global.scss";

.invoice-generated-chart-box-content-box {
  background: #ffffff;
  border: 1px solid #d1d9e6;
  border-radius: 10px;
  margin: 0 0 30px 0;

  .box-body-content-alignment {
    .empty-sapce {
      padding: 50px 0 0 0;
    }

    .grid {
      display: grid;
      grid-template-columns: 50px 1fr;
      gap: 0;
      border-bottom: 1px solid #d1d9e6;
      height: 300px;

      .grid-items:nth-child(1) {
        border-right: 1px solid #d1d9e6;
      }

      .grid-items:nth-child(2) {
        padding-left: 50px;

        .chart-items {
          // padding: 50px 0 50px 0;
          width: 100%;
          // ::-webkit-scrollbar {
          //   display: none !important;
          // }
          .staff-chart-container {
            height: 100%;
            width: 100%;
            overflow: scroll;
          }
        }
      }
    }
  }

  .box-header {
    padding: 22px;
    @include flex-center;
    justify-content: space-between;
    border-bottom: 1px solid #d1d9e6;

    .flex-items-sec {
      .sales-relative {
        position: relative;
        margin-left: 15px;

        .service-dropdown-hidden {
          max-height: 0px;
          overflow: hidden;
          transition: 0.3s ease-in-out;
        }

        .service-dropdown-show {
          max-height: calc(100vh - 0px);
          overflow: hidden;
          transition: 0.3s ease-in-out;
        }

        .service-dropdown {
          width: 100%;
          position: absolute;
          right: 0;
          background: #ffffff;
          border-radius: 6px;
          z-index: 9999;

          div {
            border: 1px solid #c4deff;
            border-radius: 6px;

            span {
              display: block;
              padding: 10px 15px;
              border-bottom: 1px solid #d1d9e6;
              @include text-sm-bold;
              color: #1479ff;
              @include pointer;
            }
          }
        }

        button {
          background: #ffffff;
          border: 1px solid #c4deff;
          border-radius: 6px;
          width: 210px;
          padding: 10px 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include pointer;

          span {
            display: block;
            @include text-small-bold;
            color: #1479ff;
          }
        }
      }
    }

    div {
      span {
        @include heading-8;
        display: block;
        color: var(--blue-color);
      }

      button {
        background: #ffffff;
        border: 1px solid #c4deff;
        border-radius: 6px;
        color: #1479ff;
        @include text-small-bold;
        cursor: pointer;
        padding: 10px 20px;
      }
    }
  }
}
