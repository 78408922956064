@import '../../../styles/mixins/global.scss';

.account-modal-manager-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background: #F7FBFF;
    z-index: 9999;
    width: 100%;
    height: 100%;
    -webkit-animation-name: round;
    animation-name: round;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;


    .full-page-body-top-alignment {
        padding: 28px 0;
        height: calc(100vh - 105px);
        overflow: auto;
        overflow-x: hidden;

        .account-manager-table-design {
            table {
                width: 100%;
                border-collapse: collapse;

                tr {
                    border-bottom: 1px solid #D1D9E6;

                    th {
                        padding: 30px 10px;
                        @include text;
                        font-weight: 500;
                        color: var(--gray-9);
                    }

                    td {
                        padding: 30px 10px;

                        .password-icon-alignment {
                            @include flex-center;
                            justify-content: space-between;

                            div+div {
                                @include flex-center;

                                img:last-child {
                                    margin-left: 22px;
                                }

                                img {
                                    @include pointer;
                                }
                            }

                            div {
                                span {
                                    @include text;
                                    font-weight: 500;
                                    color: var(--blue-color);

                                    @include breakpoint("max-sm") {
                                        padding-right: 10px;
                                    }
                                }
                            }
                        }

                        .normal-text-style {
                            span {
                                @include text;
                                font-weight: 500;
                                color: var(--blue-color);
                            }
                        }

                    }
                }
            }
        }

        .all-content-alignment {
            display: flex;
            justify-content: center;

            .account-modal-width {
                width: 1198px;

                .sub-header-modal-title {
                    h5 {
                        @include heading-8;
                        color: var(--blue-color);
                        margin: 0;
                    }
                }
            }
        }

    }

    .full-page-header-alignment {
        background-color: var(--white-color);
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;
        @include flex-center;
        justify-content: space-between;
        padding: 30px;

        .header-title {
            @include flex-center;


            div {
                width: 45px;
                cursor: pointer;
                height: 45px;
                background-color: #edf5ff;
                border-radius: 3px;
                display: flex;
                margin-right: 30px;
                align-items: center;
                justify-content: center;
            }

            h2 {
                @include heading-3;
                color: var(--blue-color);
                margin: 0;
            }
        }
    }
}