@import '../../styles/mixins/global.scss';

.select-relative-div {
    position: relative;

    .icon-alignment {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        @include flex-center;

        img {
            @include pointer;
        }
    }
}

.relative-custom-select {
    position: relative;
    margin: 0 0 0 0;
    cursor: pointer;

    .custom-select-react-dropdown-open {
        overflow: auto;
        max-height: 260px;
        border-top: none;
        transition: .3s ease-in-out;
    }

    .custom-select-react-dropdown-hidden {
        max-height: 0px;
        overflow: hidden;
        transition: .3s ease-in-out;
    }

    .custom-select-react-dropdown {
        position: absolute;
        z-index: 99999;
        box-shadow: 0 0 30px #d1d9e680;
        border-radius: 4px;
        background-color: #fff;
        width: 100%;

        .all-menu-alignment {
            .menu-list {
                padding: 15px;
                border-bottom: 1px solid #d1d9e6;
                cursor: pointer;

                span {
                    @include text-sm;
                    color: var(--blue-color);
                    font-size: 14px;
                    display: block;
                }
            }
        }
    }
}