@import '../../../styles/mixins/global.scss';

.filter-user-modal-bluer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    justify-content: center;
    background: hsla(0, 0%, 100%, .3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 999999999;

    display: flex;
    align-items: center;

    .filter-user-modal-md {
        width: 510px;
        max-height: calc(100vh - 60px);
        background: #FFFFFF;
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;

        .all-body-text-checkbox-content-alignment {
            height: calc(100vh - 250px);
            overflow: auto;
            padding-right: 30px;


            .card-box {
                @include flex-center;
                justify-content: space-between;
                margin: 0 0 22px 0;
                padding: 15px;
                background: #FFFFFF;
                border: 1px solid #7DAFF2;
                border-radius: 4px;

                span {
                    @include text-sm-bold;
                    font-weight: 500;
                    color: var(--blue-color);
                }
            }

            .card-box-selected{
                background: #F3F8FF;
            }
        }

        .filter-user-modal-body-content-alignment {
            padding: 30px;
            padding-right: 0;


            .serch-relative {
                position: relative;
                margin: 0 0 22px 0;
                padding-right: 30px;

                input {
                    width: 100%;
                    background: #FFFFFF;
                    border: 1px solid #D1D9E6;
                    border-radius: 4px;
                    padding-left: 36px;
                    padding-right: 16px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    font-family: "Poppins", sans-serif;
                    color: #193566;
                    height: 41px;

                    &:focus {
                        outline: none;
                    }

                }

                .search-icon-alignment {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex;
                    align-items: center;
                }

                ::placeholder {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    font-family: "Poppins", sans-serif;

                    color: #193566;
                }
            }
        }

        .filter-user-modal-header {
            background: #FFFFFF;
            box-shadow: 0px 4px 10px rgba(209, 217, 230, 0.5);
            padding: 25px;
            border-radius: 10px 10px 0 0;

            .header-all-content-alignment {
                @include flex-center;
                justify-content: space-between;

                .button-alignment {
                    @include flex-center;

                    span {
                        font-weight: 600;
                        font-size: 10px;
                        line-height: 15px;
                        padding-right: 15px;
                        color: #1479FF;
                        display: block;
                        cursor: pointer;
                    }

                    button {
                        background: #1479FF;
                        border-radius: 5px;
                        padding: 9px 20px;
                        font-size: 10px;
                        line-height: 15px;
                        font-weight: 600;
                        cursor: pointer;
                        border: none;
                        color: #fff;
                    }
                }

                .first-box-title-alignment {
                    @include flex-center;

                    h2 {
                        @include heading-8;
                        color: var(--blue-color);
                        margin: 0;
                    }

                    div {
                        width: 33px;
                        cursor: pointer;
                        height: 33px;
                        background-color: #edf5ff;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        margin-right: 25px;
                        justify-content: center;
                    }
                }
            }
        }
    }
}