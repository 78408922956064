@import '../../../styles/mixins/global.scss';

.add-validity-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(246, 251, 255, .5686274509803921);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999;

    .small-modal {
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;
        width: 460px;
        background-color: var(--white-color);

        .small-modal-footer {
            padding: 30px;

            .blue-button {
                display: flex;
                align-items: center;
                justify-content: center;

                button {
                    padding: 12px 21px;
                }
            }
        }

        .small-body-alignment {
            padding: 30px 22px;
            border-bottom: 1px solid #D1D9E6;

            .body-text-style {
                padding: 0 0 30px 0;

                p {
                    @include text;
                    color: var(--blue-color);
                    font-weight: 500;
                    margin: 0;
                }
            }

            .input-days-relative {
                position: relative;

                .days-text-alignment {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    @include flex-center;
                    @include pointer;
                    right: 15px;

                    span {
                        @include text-sm-medium;
                        font-size: 14px;
                        color: var(--blue-color);
                    }
                }
            }
        }

        .small-modal-header {
            padding: 25px;
            box-shadow: 0px 4px 10px rgba(209, 217, 230, 0.5);
            @include flex-center;

            h1 {
                @include heading-8;
                color: var(--blue-color);
            }

            div {
                width: 33px;
                cursor: pointer;
                height: 33px;
                background-color: #edf5ff;
                border-radius: 3px;
                display: flex;
                margin-right: 30px;
                align-items: center;
                justify-content: center;
            }
        }
    }
}