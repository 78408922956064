@import '../../../styles/mixins/global.scss';


.account-upgrade-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(246, 251, 255, .5686274509803921);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999;

    .account-upgrade-small-modal {
        width: 460px;
        background-color: var(--white-color);
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;

        @include breakpoint("max-sm") {
            width: calc(100% - 40px);
        }

        .account-upgrade-small-modal-footer {
            padding: 30px;

            @include breakpoint("max-sm") {
                padding: 20px;
            }

            border-top: 1px solid #D1D9E6;

            .blue-button {
                @include flex-center;
                justify-content: center;

                button {
                    padding: 12px 21px;
                }
            }
        }

        .account-upgrade-small-modal-body {
            padding: 30px 22px 0 22px;

            @include breakpoint("max-sm") {
                height: calc(100vh - 210px);
                overflow: auto;
            }

            .box-title-alignment {
                padding: 0 0 30px 0;

                p {
                    @include text;
                    color: var(--blue-color);
                    font-weight: 500;
                    margin: 0;
                }
            }

            .input {
                margin: 0 0 30px 0;
            }

            .account-relative-div {
                position: relative;

                .days-text-alignment {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    @include flex-center;
                    @include pointer;
                    right: 13px;

                    span {
                        @include text-sm-medium;
                        font-size: 14px;
                        color: var(--blue-color);
                        @include pointer;

                        a {
                            font-family: 'Roboto', sans-serif;
                        }
                    }
                }
            }
        }

        .account-upgrade-small-modal-header {
            @include flex-center;
            box-shadow: 0px 4px 10px rgba(209, 217, 230, 0.5);
            background-color: var(--white-color);
            padding: 25px;

            div {
                width: 33px;
                cursor: pointer;
                height: 33px;
                background-color: #edf5ff;
                border-radius: 3px;
                display: flex;
                margin-right: 25px;
                align-items: center;
                justify-content: center;
            }

            h2 {
                @include heading-8;
                color: var(--blue-color);
                margin: 0;
            }
        }
    }
}