.option-container {
  display: flex;
  align-items: center;
  gap: 15px;
  .option {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 6px;
    .bullet {
      width: 15px;
      height: 15px;
      border-radius: 3px;
    }
    .name {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #1479ff;
    }
  }
}
