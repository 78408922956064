@import '../../../styles/mixins/global.scss';

.user-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background: #F7FBFF;
    z-index: 9999;
    width: 100%;
    height: 100%;
    -webkit-animation-name: round;
    animation-name: round;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;

    .full-page-header-alignment {
        background-color: var(--white-color);
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;
        @include flex-center;
        justify-content: space-between;
        padding: 30px;

        @include breakpoint("max-sm") {
            padding: 15px;
        }

        .header-title {
            @include flex-center;


            div {
                width: 45px;
                cursor: pointer;
                height: 45px;
                background-color: #edf5ff;
                border-radius: 3px;
                display: flex;
                margin-right: 30px;
                align-items: center;
                justify-content: center;

                @include breakpoint("max-sm") {
                    width: 35px;
                    margin-right: 15px;
                    height: 35px;

                    img {
                        max-width: 10px;
                    }
                }
            }

            h2 {
                @include heading-3;
                color: var(--blue-color);
                margin: 0;

                @include breakpoint("max-sm") {
                    @include heading-6-bold;

                }
            }
        }
    }

    .full-page-body-top-alignment {
        padding: 28px 0;
        height: calc(100vh - 105px);
        overflow: auto;
        overflow-x: hidden;

        @include breakpoint("max-sm") {
            padding: 20px 0px 10px 15px;
            height: calc(100vh - 72px);
        }

        .all-content-alignment {
            display: flex;
            justify-content: center;

            .user-modal-all-data-width {
                width: 1212px;

                @include breakpoint("max-sm") {
                    width: 100%;
                }

                .user-data-table-show {
                    @include breakpoint("max-sm") {
                        width: 100%;
                        height: calc(10vh - -380px);
                        overflow: auto;
                    }

                    table {
                        width: 100%;
                        border-collapse: collapse;

                        tr {
                            border-bottom: 1px solid #D1D9E6;

                            th {
                                padding: 30px 10px;
                                @include text;
                                color: var(--gray-9);
                                font-weight: 500;

                                @include breakpoint("max-sm") {
                                    white-space: nowrap;
                                    padding: 20px 10px;

                                }
                            }

                            td {
                                padding: 30px 10px;

                                @include breakpoint("max-sm") {
                                    padding: 20px 10px;
                                    white-space: nowrap;
                                }

                                .status-button-style {
                                    button {
                                        padding: 1px 21px;
                                        height: 25px;
                                        background: #D1FAE5;
                                        font-size: 10px;
                                        line-height: 12px;
                                        color: #065F46;
                                        @include pointer;
                                        border: none;
                                        font-weight: 600;
                                        border-radius: 3px;
                                        font-family: 'Poppins', sans-serif;
                                    }
                                }

                                .status-button-style-deactive {
                                    button {
                                        padding: 1px 21px;
                                        height: 25px;
                                        background: rgba(230, 102, 102, 0.15);
                                        font-size: 10px;
                                        line-height: 12px;
                                        color: #E66666;
                                        @include pointer;
                                        border: none;
                                        font-weight: 600;
                                        border-radius: 3px;
                                        font-family: 'Poppins', sans-serif;
                                    }
                                }

                                .status-button-style-expire {
                                    button {
                                        padding: 1px 21px;
                                        height: 25px;
                                        background: rgba(111, 115, 125, 0.2);
                                        ;
                                        font-size: 10px;
                                        line-height: 12px;
                                        color: #6F737D;
                                        @include pointer;
                                        border: none;
                                        font-weight: 600;
                                        border-radius: 3px;
                                        font-family: 'Poppins', sans-serif;
                                    }
                                }


                                .paid-button-style {
                                    button {
                                        padding: 1px 21px;
                                        height: 25px;
                                        background: #E8F2FF;
                                        font-size: 10px;
                                        line-height: 12px;
                                        color: #1479FF;
                                        @include pointer;
                                        border: none;
                                        font-weight: 600;
                                        border-radius: 3px;
                                        font-family: 'Poppins', sans-serif;
                                    }
                                }

                                .normal-text-style {
                                    span {
                                        @include text;
                                        color: var(--blue-color);
                                        font-weight: 500;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }

                .sub-header-alignment {
                    @include flex-center;
                    justify-content: space-between;
                   

                    @include breakpoint("max-sm") {
                        display: block;
                    }
                    @include breakpoint("max-sm") {
                        margin-right: 16px!important; 
                    }

                    .manager-filter-align{
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        gap:200px;
                        @include breakpoint("max-sm") {
                            flex-direction: column;
                            gap:7px;
                        }

                      
                    }
                    .search-relative {
                        position: relative;
                        width: 243px;

                        @include breakpoint("max-sm") {
                            margin: 16px 0 0 0 !important;
                            width: 100%;
                            
                        }

                        .search-icon-alignment {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            @include flex-center;
                            left: 11px;
                            @include pointer;
                        }

                        input {
                            width: 100%;
                            background-color: var(--white-color);
                            padding-left: 31px;
                            padding-right: 15px;
                            border: 1px solid #E4E9F2;
                            border-radius: 5px;
                            height: 38px;
                            @include text-small-bold;
                            color: var(--blue-color);

                            &:focus {
                                outline: none;
                            }
                        }

                        ::placeholder {
                            @include text-small-bold;
                            color: var(--gray-9);
                        }
                    }

                    div {
                        h5 {
                            @include heading-8;
                            color: var(--blue-color);
                            margin: 0;

                            @include breakpoint("max-sm") {
                                @include text-md;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

.sub-button-style-filters {
    min-width: 38px;
    height: 50px;
  
    // box-shadow: 0px 0px 10px #D1D9E6;
    @include medium-border-radius;
    display: flex;
    margin-right: 18px;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    justify-content: center;
    transition: 5s ease-in-out;
    border: 1px solid #c4ddff;

    // &:hover {
    //   min-width: 143px !important;
    //   // border: 1px solid $blue-2;
    //   max-width: 143px;
    //   box-shadow: 0px 0px 10px rgba(209, 217, 230, 0.75);

    //   transition: 0.4s ease;
    // }

    &:focus {
      border: 1px solid var(--blue-color);
    }

    
  }

  .sub-button-style-filter:hover span {
    display: block !important;
  }

  .cus-invoice-icon-designs, .cus-invoice-icon-last-design {
    position: relative;
    width: 38px;
    min-width: 38px;
    height: 50px;
    margin: 0px 20px 0px 0 !important;
    box-shadow: 0px 2px 10px rgba(112, 112, 112, 0.12);
    border: 1px solid #c4ddff;
    cursor: pointer;
    background-color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: 5s ease-in-out;
    .iconic-tab {
        display: flex;
        align-items: center;
        overflow: hidden;
        .iconic-icon,
        .iconic-icon-hover {
            width: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            position: absolute;
        }
        .iconic-icon-hover {
            display: none;
        }
    }
    .iconic-title {
        // display: none;
        left: 45px;
        opacity: 0;
        position: absolute;
        transition: 0.25s;
    }
    // &:hover {
    //     justify-content: start;
    //     width: 102px;
    // }
    &:hover .iconic-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        display: block;
        color: #1479ff;
        width: max-content;
        opacity: 1;
    }
    &:hover .iconic-icon {
        display: none;
    }
    &:hover .iconic-icon-hover {
        display: flex;
    }
    &:nth-child(2) {
        margin: 0 22px;
    }
}

.filter-data-btns-paid {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;

    button {
      height: 24px;
      width: 75px;
      font-family: "Poppins";
      background: #ffffff;
      border-radius: 3px;
      padding: 0px 10px;
      color: #065F46;
      font-size: 10px;
      line-height: 23px;
      font-weight: 600;
      border: 1px solid #d1d9e6;
    }
  }

  .filter-data-btns-paid-active {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;

    button {
      height: 24px;
      width: 75px;
      font-family: "Poppins";
      background: #E7EFED;
      border-radius: 3px;
      padding: 0px 10px;
      color: #065F46;
      font-size: 10px;
      line-height: 23px;
      font-weight: 600;
      border: 1px solid #E7EFED;
    }
  }

  .filter-btn-clicked-active {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;

    button {
      height: 24px;
      width: 75px;
      font-family: "Poppins";
      background: rgba(230, 102, 102, 0.15);
      border-radius: 2px;
      padding: 0px 10px;
      color: #E66666;
      font-size: 10px;
      line-height: 23px;
      font-weight: 600;
      border: 1px solid rgba(230, 102, 102, 0.15);
    }
  }

  .filter-btn-clicked {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;

    button {
      height: 24px;
      width: 75px;
      font-family: "Poppins";
      background: #ffffff;
      border-radius: 2px;
      padding: 0px 10px;
      color: #E66666;
      font-size: 10px;
      line-height: 23px;
      font-weight: 600;
      border: 1px solid #e8f2ff;
    }
  }

  .filter-btn-clicked-expire-active {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;

    button {
      height: 24px;
      width: 75px;
      font-family: "Poppins";
      background:rgba(111, 115, 125, 0.2);
      border-radius: 2px;
      padding: 0px 10px;
      color: #6F737D;
      font-size: 10px;
      line-height: 23px;
      font-weight: 600;
      border: 1px solid rgba(111, 115, 125, 0.2);
    }
  }

  .filter-btn-clicked-expire {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;

    button {
      height: 24px;
      width: 75px;
      font-family: "Poppins";
      background: #ffffff;
      border-radius: 2px;
      padding: 0px 10px;
      color: #6F737D;
      font-size: 10px;
      line-height: 23px;
      font-weight: 600;
      border: 1px solid #e8f2ff;
    }
  }

  .filter-btn-clicked-paid-active {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;

    button {
      height: 24px;
      width: 75px;
      font-family: "Poppins";
      background: #E8F2FF;
      border-radius: 2px;
      padding: 0px 10px;
      color: #1479FF;
      font-size: 10px;
      line-height: 23px;
      font-weight: 600;
      border: 1px solid #E8F2FF;
    }
  }

  .filter-btn-clicked-paid {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;

    button {
      height: 24px;
      width: 75px;
      font-family: "Poppins";
      background: #ffffff;
      border-radius: 2px;
      padding: 0px 10px;
      color: #1479FF;
      font-size: 10px;
      line-height: 23px;
      font-weight: 600;
      border: 1px solid #e8f2ff;
    }
  }


  .cus-invoice-icon-designs-are{
    max-width: 495px;

    @include breakpoint("max-sm") {
        padding-left: 140px;
       overflow: scroll;
    }
    
  }

 