@import '../../styles/mixins/global.scss';

.header-menu-blur {
    background: rgba(246, 251, 255, .5686274509803921);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}

.header-menu-sidebar-hidden {
    transform: translateX(100%);
    transition: .3s ease-in-out;
}

.header-menu-sidebar-show {
    transform: translateX(0%);
    transition: .3s ease-in-out;
}

.header-menu-sidebar-design {
    position: fixed;
    right: 0;
    z-index: 999999;
    top: 0;
    width: 333px;
    height: 100vh;
    box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
    z-index: 999;
    background-color: var(--white-color);

    .modal-body {
        .menu-design:last-child {
            div {
                span {
                    color: #e66666 !important;
                }
            }
        }

        .menu-design {
            padding: 23px 22px;
            @include flex-center;
            border-bottom: 1px solid var(--border-color);
            @include pointer;

            div+div {
                padding-left: 10px;
            }

            div {
                @include flex-center;

                span {
                    @include text-md-bold;
                    color: var(--blue-1);
                    @include pointer;
                }
            }
        }
    }

    .modal-header-alignment {
        padding: 30px;
        @include flex-center;
        justify-content: flex-end;
        border-bottom: 1px solid var(--border-color);

        div {
            width: 45px;
            height: 45px;
            border-radius: 3px;
            @include flex-center;
            justify-content: center;
            @include pointer;
            background-color: var(--light-color);
        }
    }
}