@import '../../styles/mixins/global.scss';

.toaster-modal {
    width: 100%;
    height: 100vh;
    background-color: transparent;
    z-index: 99999999999999999999;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    animation-name: tostermodal;
    animation-duration: 1s;
    animation-timing-function: linear;
}

.modal-design-toster {
    width: 588px;
    background-color: var(--white-color);
    margin-top: 40px;
    box-shadow: 0px 0px 30px #D1D9E6;
    border-radius: 10px;
    max-height: 190px;
    padding: 30px;

    @include breakpoint("max-sm") {
        width: calc(100% - 30px);
        padding: 20px;
        max-height: 175px;
    }
}


@keyframes tostermodal {
    0% {
        transform: translateY(-100%);
    }

    25% {
        transform: translateY(-60%);
    }

    50% {
        transform: translateY(-50%);
    }

    75% {
        transform: translateY(-30%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes tostermodalClose {
    0% {
        transform: translateY(-10%);
    }

    25% {
        transform: translateY(-30%);
    }

    50% {
        transform: translateY(-50%);
    }

    75% {
        transform: translateY(-80%);
    }

    100% {
        transform: translateY(-100%);
    }
}

.toster-title {
    p {
        padding-right: 60px;
        @include text-md-bold;
        color: var(--blue-1);
        margin: 0;
        font-weight: 600;
        margin-bottom: calc(var(--normal-pixel) * 6 - 0px);
        line-height: 27px;
        height: 57px;

        @include breakpoint("max-sm") {
            padding: 0;
            font-size: 18px;
            line-height: 25px;
        }
    }

    label {
        @include text-md-bold;
        font-size: 18px;
        color: var(--blue-1);
        margin: 0;
        font-weight: 600;
        line-height: 27px;
        height: 57px;
    }

    .span-style {
        font-size: 14px;
        color: #193566;
    }
}

.toster-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        min-width: 144px;
        height: 44px;
        padding: 5px 10px;
        @include medium-border-radius;
        font-size: 15px;
        @include text-sm-bold;
        font-weight: 500;
        border: 1px solid var(--blue-1);   
            color: #fff;
        @include breakpoint("max-sm") {
            min-width: 120px;
        }
    }

    button:first-child {
        background: #fff;
        border: 1px solid var(--blue-1);
        color: var(--blue-1);
        margin-right: 30px;
    }

    .red-button-style {
        background: #E66666;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white-color);
        border: 1px solid transparent;

        span {
            // padding-left: 10px;
        }
    }

    .green-button-style {
        background: #338333;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white-color);
        border: 1px solid transparent;

        span {
            // padding-left: 10px;
        }
    }

}

.toster-footer-wallet {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    button {
        min-width: 144px;
        height: 44px;
        padding: 5px 10px;
        @include medium-border-radius;
        font-size: 15px;
        @include text-sm-bold;
        font-weight: 500;
    }

    button:first-child {
        border: 1px solid var(--blue-1);
        color: var(--blue-1);
        margin-right: 30px;
    }

    button:last-child {
        background: #E66666;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white-color);
        border: 1px solid transparent;

        span {
            // padding-left: 10px;
        }
    }

}

.toster-footer-wallet-green {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    button {
        min-width: 144px;
        height: 44px;
        padding: 5px 10px;
        @include medium-border-radius;
        font-size: 15px;
        @include text-sm-bold;
        font-weight: 500;
    }

    button:first-child {
        border: 1px solid var(--blue-1);
        color: var(--blue-1);
        margin-right: 30px;
    }

    button:last-child {
        background: #338333;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white-color);
        border: 1px solid transparent;

        span {
            // padding-left: 10px;
        }
    }

}

.toster-footer-SMS-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        min-width: 144px;
        height: 44px;
        padding: 5px 10px;
        @include medium-border-radius;
        @include text-sm-bold;
        font-weight: 500;
        font-size: 15px;
    }

    button:first-child {
        border: 1px solid var(--blue-1);
        color: var(--blue-1);
        margin-right: 30px;
    }

    button:last-child {
        background: #1479FF;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white-color);
        border: 1px solid transparent;

        span {
            padding-left: 10px;
        }
    }

}