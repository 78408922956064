@import '../../../styles/mixins/global.scss';
.plan-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background: #F7FBFF;
    z-index: 9999;
    width: 100%;
    height: 100%;
    -webkit-animation-name: round;
    animation-name: round;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;

    .full-page-header-alignment {
        background-color: var(--white-color);
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;
        @include flex-center;
        justify-content: space-between;
        padding: 30px;

        @include breakpoint("max-sm") {
            padding: 15px;
        }

        .header-title {
            @include flex-center;


            div {
                width: 45px;
                cursor: pointer;
                height: 45px;
                background-color: #edf5ff;
                border-radius: 3px;
                display: flex;
                margin-right: 30px;
                align-items: center;
                justify-content: center;

                @include breakpoint("max-sm") {
                    width: 35px;
                    margin-right: 15px;
                    height: 35px;

                    img {
                        max-width: 10px;
                    }
                }
            }

            h2 {
                @include heading-3;
                color: var(--blue-color);
                margin: 0;

                @include breakpoint("max-sm") {
                    @include heading-6-bold;

                }
            }
        }
    }

    .full-page-body-top-alignment {
        padding: 28px 0;
        height: calc(100vh - 105px);
        overflow: auto;
        overflow-x: hidden;

        @include breakpoint("max-sm") {
            padding: 20px 0px 10px 15px;
            height: calc(100vh - 72px);
        }

        .all-content-alignment {
            display: flex;
            justify-content: center;

            .user-modal-all-data-width {
                width: 1212px;

                @include breakpoint("max-sm") {
                    width: 100%;
                }

                .user-data-table-show {
                    @include breakpoint("max-sm") {
                        width: 100%;
                        height: calc(10vh - -380px);
                        overflow: auto;
                    }

                    table {
                        width: 100%;
                        border-collapse: collapse;

                        tr {
                            border-bottom: 1px solid #D1D9E6;

                            th {
                                padding: 30px 10px;
                                @include text;
                                color: var(--gray-9);
                                font-weight: 500;

                                @include breakpoint("max-sm") {
                                    white-space: nowrap;
                                    padding: 20px 10px;

                                }
                            }

                            td {
                                padding: 30px 10px;

                                @include breakpoint("max-sm") {
                                    padding: 20px 10px;
                                    white-space: nowrap;
                                }

                                .status-button-style {
                                    button {
                                        padding: 1px 21px;
                                        height: 25px;
                                        background: #D1FAE5;
                                        font-size: 10px;
                                        line-height: 12px;
                                        color: #065F46;
                                        @include pointer;
                                        border: none;
                                        font-weight: 600;
                                        border-radius: 3px;
                                        font-family: 'Poppins', sans-serif;
                                    }
                                }

                                .status-button-style-deactive {
                                    button {
                                        padding: 1px 21px;
                                        height: 25px;
                                        background: rgba(230, 102, 102, 0.15);
                                        font-size: 10px;
                                        line-height: 12px;
                                        color: #E66666;
                                        @include pointer;
                                        border: none;
                                        font-weight: 600;
                                        border-radius: 3px;
                                        font-family: 'Poppins', sans-serif;
                                    }
                                }

                                .status-button-style-expire {
                                    button {
                                        padding: 1px 21px;
                                        height: 25px;
                                        background: rgba(111, 115, 125, 0.2);
                                        ;
                                        font-size: 10px;
                                        line-height: 12px;
                                        color: #6F737D;
                                        @include pointer;
                                        border: none;
                                        font-weight: 600;
                                        border-radius: 3px;
                                        font-family: 'Poppins', sans-serif;
                                    }
                                }


                                .paid-button-style {
                                    button {
                                        padding: 1px 21px;
                                        height: 25px;
                                        background: #E8F2FF;
                                        font-size: 10px;
                                        line-height: 12px;
                                        color: #1479FF;
                                        @include pointer;
                                        border: none;
                                        font-weight: 600;
                                        border-radius: 3px;
                                        font-family: 'Poppins', sans-serif;
                                    }
                                }

                                .normal-text-style {
                                    span {
                                        @include text;
                                        color: var(--blue-color);
                                        font-weight: 500;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }

                .sub-header-alignment {
                    @include flex-center;
                    justify-content: space-between;


                    @include breakpoint("max-sm") {
                        display: block;
                    }

                    @include breakpoint("max-sm") {
                        margin-right: 16px !important;
                    }

                    .manager-filter-align {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        gap: 200px;

                        @include breakpoint("max-sm") {
                            flex-direction: column;
                            gap: 7px;
                        }


                    }

                    .search-relative {
                        position: relative;
                        width: 243px;

                        @include breakpoint("max-sm") {
                            margin: 16px 0 0 0 !important;
                            width: 100%;

                        }

                        .search-icon-alignment {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            @include flex-center;
                            left: 11px;
                            @include pointer;
                        }

                        input {
                            width: 100%;
                            background-color: var(--white-color);
                            padding-left: 31px;
                            padding-right: 15px;
                            border: 1px solid #E4E9F2;
                            border-radius: 5px;
                            height: 38px;
                            @include text-small-bold;
                            color: var(--blue-color);

                            &:focus {
                                outline: none;
                            }
                        }

                        ::placeholder {
                            @include text-small-bold;
                            color: var(--gray-9);
                        }
                    }

                    div {
                        h5 {
                            @include heading-8;
                            color: var(--blue-color);
                            margin: 0;

                            @include breakpoint("max-sm") {
                                @include text-md;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}