body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: #F6FBFF;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

p:last-child {
  margin: 0;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

a {
  text-decoration: none;
}



::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F8F8F8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #A6A6A6;
  border-radius: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F8F8F8;
}

/* Remove input type number arrows */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

