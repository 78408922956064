@import '../../../styles/mixins/global.scss';

.full-page-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background: #F7FBFF;
  z-index: 9999;
  width: 100%;
  height: 100%;
  -webkit-animation-name: round;
  animation-name: round;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;

  .full-page-body-top-alignment {
    padding: 28px 0;
    height: calc(100vh - 105px);
    overflow: auto;
    overflow-x: hidden;

    .box-center-alignment {
      @include flex-center;
      justify-content: center;

      .account-data-box {
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        border-radius: 10px;
        width: 580px;
        background-color: var(--white-color);

        @include breakpoint("max-sm") {
          width: 100%;
        }

        .input {
          margin: 0 0 22px 0;

          .relative-div {
            position: relative;

            .day-text-alignment {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 15px;
              @include flex-center;

              span {
                @include text-sm-medium;
                color: var(--blue-color);
                display: block;
                @include pointer;
                font-size: 14px;

                a {
                  font-family: 'Roboto', sans-serif !important;
                }
              }
            }
          }

          .box-body-alignment {
            padding: 15px 22px;

            .input {
              .relative-div {
                position: relative;

                .icon-alignment {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  @include flex-center;
                  justify-content: center;
                  @include pointer;
                  right: 15px;
                }
              }
            }
          }

        }

        .box-body-alignment {
          padding: 15px 22px;

          .input {
            .relative-div {
              position: relative;

              .icon-alignment {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                @include flex-center;
                justify-content: center;
                @include pointer;
                right: 15px;
              }
            }
          }
        }

        .box-title {
          padding: 15px 22px;
          border-bottom: 1px solid var(--border-color-1);

          p {
            @include text-md-bold;
            color: var(--blue-color);
          }
        }
      }
    }
  }

  .full-page-header-alignment {
    background-color: var(--white-color);
    box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
    border-radius: 10px;
    @include flex-center;
    justify-content: space-between;
    padding: 30px;

    @include breakpoint("max-sm") {
      padding: 15px;
    }

    .header-title {
      @include flex-center;


      div {
        width: 45px;
        cursor: pointer;
        height: 45px;
        background-color: #edf5ff;
        border-radius: 3px;
        display: flex;
        margin-right: 30px;
        align-items: center;
        justify-content: center;

        @include breakpoint("max-sm") {
          width: 35px;
          height: 35px;
          margin-right: 15px;

          img {
            max-width: 14px;
          }
        }
      }

      h2 {
        @include heading-3;
        color: var(--blue-color);
        margin: 0;

        @include breakpoint("max-sm") {
          @include heading-6-bold;
        }
      }
    }
  }
}