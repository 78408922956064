body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  font-weight: 500 !important;
}
.react-datepicker__header {
  background-color: #f7fbff !important
}    





.react-datepicker__day--selected{
  color: #FFFFFF !important;
}
.react-datepicker__month{

  padding: 0.6rem;
  margin: 0 !important;

}
.react-datepicker{
  font-family: "Poppins", sans-serif !important;
  border: none;
  
}

.react-datepicker__day{
  color: #193566!important;
}
.react-datepicker__day--in-range{
  color: #FFFFFF !important;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  color: #FFFFFF !important;
}
.react-datepicker__day.react-datepicker__day--selected {
  color: #FFFFFF !important;
}
.react-datepicker{
  font-family: 'Poppins', sans-serif !important;
}

.react-datepicker__input-container{
  width:170px !important
}

.react-datepicker__header{
  background-color: #ecf0f3 !important; 
  border: none !important;

}

.react-datepicker-time__header {
  font-family: "Poppins", sans-serif !important;
  color: #193566!important;
}

