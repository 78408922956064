@import "../../../styles/mixins/global.scss";

.analytics-modal-wrapper-contnet-alignment {
    position: fixed;
    top: 0;
    left: 0;
    background: #f7fbff;
    z-index: 999999999;
    -webkit-animation-name: round;
    animation-name: round;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    width: 100%;
    height: 100%;

    .analytics-modal-body-content-alignment {
        height: calc(100vh - 110px);
        overflow: auto;
        padding: 30px;

        .invoice-vs-appontments {
            background: #FFFFFF;
            box-shadow: 0px 0px 20px rgba(209, 217, 230, 0.25);
            padding: 30px;
            margin: 0 0 30px 0;
            border-radius: 10px;

            h4 {
                font-weight: 600;
                margin: 0 0 22px 0;
                font-size: 22px;
                color: #193566;
                line-height: 33px;
            }

            .two-form-group {
                display: flex;
                margin: 0 0 22px 0;

                align-items: center;

                background: #FFFFFF;
                display: flex;
                align-items: center;
                border: 1px solid #D1D9E6;
                border-radius: 5px;
                width: fit-content;

                button:last-child {
                    border-right: none;
                }

                button {
                    border: none;
                    cursor: pointer;
                    padding: 15px;
                    display: flex;
                    align-items: center;
                    background-color: transparent;
                    color: #193566;
                    font-family: 'Poppins', sans-serif;
                    gap: 10px;
                    border-right: 1px solid #D1D9E6;


                    span {
                        display: block;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;

                    }
                }
            }

            .chart-image-style {
                border: 1px solid #E4E9F2;
            }
        }

        .body-first-header-alignment {
            display: flex;
            align-items: center;
            padding: 0 0 30px 0;

            .total-icon {
                background: #FFFFFF;
                display: flex;
                align-items: center;
                border: 1px solid #D1D9E6;
                border-radius: 5px;
                margin-right: 15px;

                button:last-child {
                    border-right: none;
                }

                button {
                    border: none;
                    cursor: pointer;
                    padding: 15px !important;
                    display: flex;
                    align-items: center;
                    background-color: transparent;
                    color: #193566;
                    font-family: 'Poppins', sans-serif;
                    gap: 10px;
                    border-right: 1px solid #D1D9E6;


                    span {
                        display: block;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        width: 100%;
                        padding-right: 10px !important;
                        // margin-right: 15px !important;
                    }
                }

            }

            .rest-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .right-content-alignment {
                    display: flex;
                    align-content: center;
                    gap: 22px;

                    .last-month-input-box {
                        width: 231px;
                        position: relative;
                        cursor: pointer;

                        .input-relative {
                            position: relative;
                        }

                        .input-dropdown-hidden {
                            max-height: 0px;
                            overflow: hidden;
                            transition: .3s ease-in-out;
                        }

                        .input-dropdown-show {
                            max-height: calc(100vh - 0px);
                            overflow: hidden;
                            transition: .3s ease-in-out;
                        }

                        .input-dropdown {
                            width: 100%;
                            position: absolute;
                            z-index: 9999;

                            .input-dropdown-design {
                                background-color: #fff;
                                box-shadow: 0 0 10px #d1d9e6;
                                border-radius: 3px;
                                border: 1px solid #d1d9e6;

                                ul {
                                    margin: 0;
                                    padding: 0;
                                    list-style: none;

                                    li {
                                        padding: 15px;
                                        text-decoration: none;
                                        border-bottom: 1px solid #d1d9e6;
                                        font-family: "Poppins", sans-serif;
                                        font-size: 14px;
                                        font-weight: 500;
                                        cursor: pointer;
                                        line-height: 21px;
                                        color: #193566;
                                    }
                                }
                            }
                        }

                        input {
                            background: #FFFFFF;
                            border: 1px solid #D1D9E6;
                            padding: 15px;
                            border-radius: 5px;
                            font-family: 'Poppins', sans-serif;
                            height: 53px;
                            width: 100%;
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 22px;
                            color: #193566;

                            &:focus {
                                outline: none;
                            }
                        }

                        .down-icon-alignment {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            display: flex;
                            align-items: center;
                            right: 15px;
                        }

                        ::placeholder {
                            font-weight: 500;
                            color: #193566;
                            font-size: 15px;
                            line-height: 22px;
                        }
                    }

                    .all-user-selected {
                        button {
                            color: #193566;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            font-weight: 500;
                            background: #FFFFFF;
                            border: 1px solid #D1D9E6;
                            box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.25);
                            border-radius: 5px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            line-height: 22px;
                            font-family: 'Poppins', sans-serif;
                            padding: 15px;
                        }
                    }
                }

                .left-content-alignment {
                    background: #FFFFFF;
                    display: flex;
                    align-items: center;
                    border: 1px solid #D1D9E6;
                    border-radius: 5px;

                    button:last-child {
                        border-right: none;
                    }

                    button {
                        border: none;
                        cursor: pointer;
                        padding: 15px;
                        display: flex;
                        align-items: center;
                        background-color: transparent;
                        color: #193566;
                        font-family: 'Poppins', sans-serif;
                        gap: 10px;
                        border-right: 1px solid #D1D9E6;


                        span {
                            display: block;
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 22px;

                        }
                    }
                }
            }
        }
    }



    .analytics-modal-header {
        background: #ffffff;
        box-shadow: 0px 0px 30px rgba(209, 217, 230, 0.5);
        padding: 30px;

        .all-content-alignment {
            display: flex;
            gap: 30px;
            align-items: center;

            p {
                font-weight: 600;
                font-size: 30px;
                color: #193566;
                line-height: 45px;
            }

            div {
                background: rgba(20, 121, 255, 0.08);
                border-radius: 3px;
                width: 45px;
                height: 45px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}