@mixin flex-center {
    display: flex;
    align-items: center;
}

@mixin flex-between {
    @include flex-center();
    justify-content: space-between;
}

@mixin text-center {
    text-align: center;
}

@mixin block {
    display: block;
}

@mixin pointer {
    cursor: pointer;
}

@mixin medium-border-radius {
    border-radius: 3px;
  }